import {
  AbsoluteWrapper,
  Description,
  Image,
  PositionedContainer,
  Section,
  Title,
  Wrapper,
} from "./styles";
import BackgroundImage from "gatsby-background-image";
import LayoutCenterColumn from "@components/layout-center-column";
import { Link } from "gatsby";
import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useImage } from "@queries/use-image";

const data = [
  {
    imageFilename: "girl-smile-hat",
    title: "Comunidade treetree2",
    href: "/voluntariar",
    description:
      "Junta-te à nossa treebo. Faz parte desta rede extensível de conhecimento.",
  },
  {
    imageFilename: "brotherhood",
    title: "Apoia a treetree2",
    href: "/apoiar",
    description:
      "O teu donativo é importante. Sabe aqui como podes apoiar a treebo.",
  },
];

const Announcement = () => {
  const breakpoints = useBreakpoint();
  const { getFluidImageName, getFluidImageNameAndExtension } = useImage();
  const bgFluid = getFluidImageNameAndExtension("middle-mesh", "png", true);
  const bgFluidImageStack = [
    `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`,
    bgFluid,
  ];

  return (
    <PositionedContainer>
      <LayoutCenterColumn>
        {breakpoints.mobile ? null : (
          <AbsoluteWrapper>
            <div>
              {" "}
              <BackgroundImage
                style={{ width: "100%", height: "100%" }}
                fadeIn={false}
                fluid={bgFluidImageStack}
              />
            </div>
          </AbsoluteWrapper>
        )}
        <Wrapper>
          {data.map((entry, index) => {
            return (
              <Section key={index}>
                <Link aria-label={entry.title} to={entry.href}>
                  <Image fluid={getFluidImageName(entry.imageFilename)} />
                </Link>
                <Title to={entry.href}>{entry.title}</Title>
                <Description>{entry.description}</Description>
              </Section>
            );
          })}
        </Wrapper>
      </LayoutCenterColumn>
    </PositionedContainer>
  );
};

export default Announcement;
