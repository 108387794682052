import { graphql, useStaticQuery } from "gatsby";
import { isValidString } from "@utilities/string";

export const useSponsors = () => {
  const data = useStaticQuery(
    graphql`
      query {
        sponsors: allSponsorsJson {
          nodes {
            data {
              link
              name
              imageFileName
            }
          }
        }

        images: allFile(
          filter: {
            relativeDirectory: { eq: "sponsors" }
            sourceInstanceName: { eq: "images" }
          }
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  );

  const getFluidImageByName = (name) => {
    if (!isValidString(name)) {
      return null;
    }
    if (data.images?.nodes.length === 0) {
      return null;
    }

    // NOTE: The search for the image by name, in this case, is Case Sensitive
    const image = data.images?.nodes.find((node) => node.name === name);
    return image?.childImageSharp?.fluid || null;
  };

  const getSponsorsWithLogos = () => {
    const rawSponsors = data?.sponsors?.nodes[0]?.data || [];
    if (rawSponsors.length === 0) {
      return [];
    }

    return rawSponsors
      .map((rawSponsor) => {
        const { imageFileName, ...rest } = rawSponsor;
        const fluid = getFluidImageByName(imageFileName);

        if (fluid) {
          return { ...rest, fluid };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  return {
    getSponsorsWithLogos,
  };
};
