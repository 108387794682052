import Announcement from "@sections/homepage/announcement/component";
import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Hero from "@components/hero";
import HeroContent from "@sections/homepage/hero-content/hero-content-component";
import HomeIntro from "@sections/homepage/intro/intro-component";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import ProjectsCarousel from "@sections/homepage/projects-carousel/projects-carousel-component";
import React from "react";
import Seo from "@components/seo";
import Sponsors from "@components/sponsors/sponsors-component";
import { useImage } from "@queries/use-image";

export default function Home() {
  const { getFluidImageNameAndExtension } = useImage();
  const fluidHeroImage = getFluidImageNameAndExtension(
    "home-page-top-bg",
    "png",
    true
  );

  const bgFluidImageStack = [
    `linear-gradient(rgb(5, 0, 57, 0.3), rgb(5, 0, 57, 0.3))`,
    fluidHeroImage,
    `linear-gradient(rgb(5, 0, 57), rgb(5, 0, 57))`,
  ];

  const seoImage = getFluidImageNameAndExtension("brown-dashboard-math", "jpg");

  return (
    <Layout isHeroAvailable={true}>
      <FullBleed>
        <Hero fluidImage={bgFluidImageStack} fillViewport={true}>
          <HeroContent />
        </Hero>
      </FullBleed>

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 40 }} />

      <Sponsors />

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 60 }} />

      <HomeIntro />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 70 }} />

      <ProjectsCarousel
        title="Projetos aliciantes"
        subtitle="Ideas matter. Actions matter. São ambas importantes."
      />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 40 }} />

      <h2>Comunidade</h2>

      <FullBleed>
        <Announcement />
      </FullBleed>

      <FullBleed>
        <Newsletter />
      </FullBleed>

      <Seo
        title="TreeTree2 - Aprendizagem Científica em Portugal"
        description="Estamos a mudar Portugal. Desenvolvemos projetos na área da Ciência que ajudam as crianças e os jovens a cumprir o seu potencial criativo e intelectual."
        image={seoImage}
      />
    </Layout>
  );
}
