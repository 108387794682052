import BackgroundImage from "gatsby-background-image";
import LayoutCenterColumn from "@components/layout-center-column";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import styles from "./index.module.scss";

const VIEWPORT_HEIGHT = "100vh";
const STANDARD_HEIGHT_DESKTOP = "600px";
const STANDARD_HEIGHT_MOBILE = "500px";

const Wrapper = styled.section`
  outline: yellow dashed 1px;
  height: ${(props) => {
    return props.fillViewport ? VIEWPORT_HEIGHT : STANDARD_HEIGHT_DESKTOP;
  }};

  @media (max-width: 850px) {
    height: ${(props) => {
      return props.fillViewport ? VIEWPORT_HEIGHT : STANDARD_HEIGHT_MOBILE;
    }};
  }
`;

const StackedContent = styled.div`
  display: grid;
  grid-template-areas: "hero";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    position: initial;
  }
`;

const Background = styled.div`
  grid-area: hero;
  z-index: 1;
`;

const Foreground = styled.div`
  grid-area: hero;
  z-index: 2;
  align-self: center;
`;

const Lines = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 50%;
  display: inline-block;
  line-height: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BlueBlock = styled.div`
  display: inline-block;
  height: 10px;
  background-color: #0080a7;
  width: 33%;
`;

const OrangeBlock = styled.div`
  display: inline-block;
  height: 10px;
  background-color: #f9835f;
  width: 33%;
`;

const RedBlock = styled.div`
  display: inline-block;
  height: 10px;
  background-color: #e43f5a;
  width: 33%;
`;

const FloatingSideLabel = styled.h2`
  @media (max-width: 1110px) {
    /* display: none; */
    right: -150px;
  }

  position: absolute;
  z-index: 1;

  /* 210px is the height of the floating label with the white line */
  /* having top set to this value, places it in the same horizontal level as the parent container */
  /* Making it easier to add margin to both of them in the same time */
  bottom: -15px;
  right: -100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  transform-origin: top left;
  transform: rotate(-90deg);
  margin-left: -81px;

  /* Draw line before the label */

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    font-weight: bold;
    margin-right: 40px;
    width: 100px;
    color: white;
  }
`;

const Hero = (props) => {
  return (
    <Wrapper fillViewport={props.fillViewport}>
      <StackedContent>
        {props.fluidImage && (
          <Background>
            <BackgroundImage
              style={{ width: "100%", height: "100%" }}
              fluid={props.fluidImage}
              fadeIn={false}
            />
          </Background>
        )}

        <Foreground>
          <LayoutCenterColumn>{props.children}</LayoutCenterColumn>
        </Foreground>
        {props.fillViewport && (
          <Lines>
            <BlueBlock />
            <OrangeBlock />
            <RedBlock />
          </Lines>
        )}
        <FloatingSideLabel>SCROLL</FloatingSideLabel>
      </StackedContent>
    </Wrapper>
  );
};

Hero.defaultProps = {
  fillViewport: false,
};

Hero.propTypes = {
  children: PropTypes.node,
  fluidImage: PropTypes.object,
  fillViewport: PropTypes.bool,
};

export default Hero;
