import {
  Container,
  FloatingSideLabel,
  FloatingTopLabel,
  Item,
  Wrapper,
} from "./styles";
import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import { useSponsors } from "@queries/use-sponsors";

const SponsorsComponent = () => {
  const [sponsors, setSponsors] = useState([]);
  const { getSponsorsWithLogos } = useSponsors();

  useEffect(() => {
    setSponsors(getSponsorsWithLogos());
  }, []);

  return (
    <Container>
      <FloatingSideLabel>apoios</FloatingSideLabel>
      <FloatingTopLabel>apoios</FloatingTopLabel>
      <Wrapper>
        {sponsors.map((sponsor) => {
          const { name, link, fluid } = sponsor;

          return (
            <Item key={link}>
              <a
                href={link}
                title={name}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Img
                  style={{ height: "100%", width: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                  fluid={fluid}
                  alt={name}
                />
              </a>
            </Item>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default SponsorsComponent;
