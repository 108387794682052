import Button from "@components/button";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 540px;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 13px;

  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 49px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 27px;
  }
`;

const HeroContent = () => {
  return (
    <Container>
      <Title>bem-vindo à treebo</Title>
      <Paragraph>
        somos uma organização sem fins lucrativos que pretende cumprir o
        potencial criativo e intelectual dos jovens
      </Paragraph>
      <Link to="/about/">
        <Button size="large" color="black" bg="white" border="none">
          sabe mais
        </Button>
      </Link>
    </Container>
  );
};

export default HeroContent;
