import Img from "gatsby-image";
import { Link } from "gatsby";
import styled from "styled-components";

export const PositionedContainer = styled.div`
  position: relative;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  overflow: hidden;
  margin: 0 auto;

  * {
    height: 100%;
  }
`;

export const Image = styled(Img)`
  background-color: transparent;

  border-radius: 5px;
  margin-bottom: 43px;
  border: 1px solid transparent;
  picture {
    object-fit: cover;
  }

  &:hover {
    border: 1px solid #060038;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    min-height: 250px;
  }
`;

export const Title = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Description = styled.p`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Section = styled.div``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 29px;
  grid-row-gap: 0px;
  margin-top: 120px;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 110px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);

    & > ${Section} + ${Section} {
      margin-top: 60px;
    }
  }
`;
