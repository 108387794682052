import styled from "styled-components";

export const Container = styled.aside`
  position: relative;
  width: 100%;
`;

export const FloatingSideLabel = styled.h2`
  position: absolute;

  /* 210px is the height of the floating label with the black line */
  /* having top set to this value, places it in the same horizontal level as the parent container */
  /* Making it easier to add margin to both of them in the same time */
  top: 120px;

  left: 0;
  color: #060038;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  transform-origin: top left;
  transform: rotate(-90deg);
  margin-left: -81px;

  /* Draw line before the label */

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid #060038;
    border-top: 1px solid #060038;
    font-weight: bold;
    margin-right: 40px;
    width: 80px;
    color: #060038;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 50px;

  justify-items: center;

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(123px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-column-gap: 20px;
  }
`;

export const Item = styled.div`
  height: 98px;
  width: 156px;

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 80px;
    width: 123px;
  }

  @media (max-width: 768px) {
    height: 62px;
    width: 100px;
  }
`;

export const FloatingTopLabel = styled.p`
  @media (min-width: 768px) {
    display: none;
  }
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;
