import {
  Container,
  Content,
  Media,
  Paragraph,
  StyledImg,
  Title,
} from "./styles";
import React from "react";
import { useImage } from "@queries/use-image";

const HomeIntroComponent = () => {
  const { getFluidImageNameAndExtension } = useImage();
  return (
    <Container>
      <Content>
        <Title>O que vais gostar de ter feito?</Title>
        <Paragraph>
          Somos um grupo de jovens (na sua maioria, cientistas e engenheiros)
          que pretende organizar para os alunos do Ensino Básico ao Superior
          aquilo que nós gostávamos de ter feito quando éramos mais novos.
        </Paragraph>
        <Paragraph>
          ​Desde 2015, organizamos iniciativas para alunos curiosos e motivados,
          ajudando-os a definir e levar a cabo os seus próprios projectos de
          aprendizagem e exploração.
        </Paragraph>
      </Content>

      <Media>
        <StyledImg
          fluid={getFluidImageNameAndExtension("orange-student-face", "jpg")}
          alt="Cara do estudante"
        />
      </Media>
    </Container>
  );
};

export default HomeIntroComponent;
